<template>
    <Loader />
    <Navbar />
      <Header />
      <About />
      <Information />
      <VueContact />
    <FooterVue />
</template>

<script>
import Navbar from "@/components/Navbar";
import About from "@/Sections/About";
import Information from "@/Sections/Information";
import VueContact from "@/Sections/VueContact";
import FooterVue from "@/components/FooterVue";
import Header from "@/components/Header";
import Loader from "@/components/Loader";
export default {
  name: 'App',
  components: {Loader, Header, FooterVue, Navbar, VueContact, Information, About},
  mounted() {
    const upButton = document.createElement('button');
    upButton.setAttribute('id', 'btn-up');
    upButton.setAttribute('class', 'btn-up');
    upButton.innerHTML = `
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10.707 3.293a1 1 0 00-1.414 0L3.293 9.707a1 1 0 101.414 1.414L9 6.414V17a1 1 0 102 0V6.414l4.293 4.293a1 1 0 001.414-1.414l-6.414-6.414z" clip-rule="evenodd"></path>
      </svg>
    `;
    document.body.appendChild(upButton);

    // show button when scroll
    window.addEventListener('scroll', () => {
      const upButton = document.getElementById('btn-up');
      if (window.scrollY > 100) {
        upButton.classList.add('show');
      } else {
        upButton.classList.remove('show');
      }
    });

    // up the page when click
    upButton.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    const fadeOut = () => {
      const loaderWrapper = document.querySelector('.wrapper');
      loaderWrapper.classList.add('fade');
    };

    window.addEventListener('load', fadeOut);

    // collapse navbar
    const collapse = document.querySelectorAll('[data-collapse-toggle]');
    collapse.forEach((item) => {
      item.addEventListener('click', () => {
        const target = item.getAttribute('data-collapse-toggle');
        const targetElement = document.getElementById(target);
        targetElement.classList.toggle('hidden');
      });
    });

    // remove right click
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });

    // remove select
    document.addEventListener('selectstart', (e) => {
      e.preventDefault();
    });

    // remove F12 and Ctrl + Shift + I  and Ctrl + maj + c and cmd + maj + c
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73) || (e.ctrlKey && e.keyCode === 85) || (e.ctrlKey && e.shiftKey && e.keyCode === 67) || (e.metaKey && e.shiftKey && e.keyCode === 67)) {
        e.preventDefault();
      }
    });

    // function AlertMesssage() {
    //   const alert = document.getElementById('toast-warning');
    //   alert.classList.remove('hidden');
    //   setTimeout(() => {
    //     alert.classList.add('hidden');
    //   }, 3000);
    // }

    // disable select on image
    document.querySelectorAll('img').forEach((img) => {
      img.addEventListener('dragstart', (e) => {
        e.preventDefault();
      });
    });
    // if scroll is on section #Formations make webkit scroll background to white
    window.addEventListener('scroll', () => {
      const section = document.getElementById('Formations');
      const sectionPosition = section.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;
      if (sectionPosition < screenPosition) {
        document.body.classList.add('scroll');
      } else {
        document.body.classList.remove('scroll');
      }
    });
    // else on section #Contact or #About remove scroll class
    window.addEventListener('scroll', () => {
      const section = document.getElementById('Contact');
      const sectionPosition = section.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;
      if (sectionPosition < screenPosition) {
        document.body.classList.remove('scroll');
      }
    });
  }
}


</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Helvetica, sans-serif;
  overflow: overlay;
}

.wrapper {
  position: fixed;
  inset: 0;
  z-index: 9999;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  transition: opacity 0.25s, visibility 1s;
}

.wrapper.fade {
  opacity: 0;
  visibility: hidden;
}

.wrapper .loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #29398c; /* Blue */
  animation: loader 1s infinite linear;
}


@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  h1 {
    text-align: center;
  }
}


.header-bg {
  background-size: cover;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("./assets/BG.png") no-repeat center center;
  height: 70vh;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btn-up {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #1f2937;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.btn-up.show {
  opacity: 1;
  visibility: visible;
}

.btn-up svg {
  width: 20px;
  height: 20px;
}

#About {
  background: #ffffff;
  color: #fff;
}

#Formations {
  background: rgb(41,57,140);
  background: linear-gradient(140deg, rgba(41,57,140,1) 84%, rgba(255,255,255,0.8673740053050398) 100%);
}

#Contact {
  background: #161b22;
  padding: 50px 0;
}

::-webkit-scrollbar-track
{
  border-radius: 10px;
}

::-webkit-scrollbar
{
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #29398c;
}

.scroll::-webkit-scrollbar-thumb
{
  background: #262525;
}
</style>
