<template>
  <section id="Formations">
    <div class="p-32 px-4 mx-auto">
      <h2 class="mb-8 text-3xl font-bold text-center text-gray-100 ">Formations</h2>
      <div class="flex justify-center">
        <div class="flex flex-col p-5 px-auto px-20 rounded-lg shadow-lg bg-gray-800 max-w-[80%] min-w-[70%]">
          <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-[#29398c] rounded-full dark:bg-[#29398c]">
            <svg class="w-6 h-6 text-white font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </div>
          <div class="flex justify-center items-center flex-wrap mt-5">
            <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span id="totalhours" class="mx-2 text-sm font-extrabold text-gray-100">4h</span>
          </div>
            <div id="myTabContent" class="mb-3">
              <div class="flex justify-center flex-col p-4 rounded-lg bg-gray-800" id="profile" role="tabpanel" aria-labelledby="first-tab">
                <div class="flex flex-col items-center justify-center mb-5">
                  <h3 class="mb-2 text-lg font-semibold text-gray-200">Étape N°1</h3>
                </div>
                <p class="text-sm text-gray-100 text-center">
                  <b>Choisir une structure juridique appropriée, comment créer mon entreprise ?</b>
                </p>
                <div class="inline-flex justify-center items-center w-full">
                  <hr class="my-8 w-64 h-1  rounded border-0 bg-gray-700">
                  <div class="absolute left-1/2 px-4  -translate-x-1/2 bg-gray-800">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-300" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <h3 class="mb-2 text-lg font-semibold  text-gray-200">Sous étape</h3>
                </div>
                <p class="mt-2 text-sm text-gray-100 text-center">
                  <b>Présentation des différentes formes (Présentation de legalstart).</b>
                </p>
              </div>
              <div class="hidden p-4 rounded-lg bg-gray-800" id="dashboard" role="tabpanel" aria-labelledby="second-tab">
                <div class="flex flex-col items-center justify-center mb-5">
                  <h3 class="mb-2 text-lg font-semibold  text-gray-200">Étape N°2</h3>
                </div>
                <p class="text-sm text-gray-100 text-center">
                  <b>Définir son projet: trouver ses Clients en etudiant son marché et en se démarquant de ses concurrents.</b>
                </p>
                <div class="inline-flex justify-center items-center w-full">
                  <hr class="my-8 w-64 h-1  rounded border-0 bg-gray-700">
                  <div class="absolute left-1/2 px-4  -translate-x-1/2 bg-gray-800">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-300" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <h3 class="mb-2 text-lg font-semibold  text-gray-200">Sous étape</h3>
                </div>
                <p class="mt-2 text-sm text-gray-100 flex justify-center text-center">
                  <b>Définir les Besoins et la rentabilité de son projet.</b>
                </p>
              </div>
              <div class="hidden p-4 rounded-lg bg-gray-800" id="settings" role="tabpanel" aria-labelledby="third-tab">
                <div class="flex flex-col items-center justify-center mb-5">
                  <h3 class="mb-2 text-lg font-semibold text-gray-200">Étape N°3</h3>
                </div>
                <p class="text-sm text-gray-100 text-center">
                  <b>L’Entrepreneur: mettre ses compétences au service de son projet.</b>
                </p>
                <div class="inline-flex justify-center items-center w-full">
                  <hr class="my-8 w-64 h-1  rounded border-0 bg-gray-700">
                  <div class="absolute left-1/2 px-4  -translate-x-1/2 bg-gray-800">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-300" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <h3 class="mb-2 text-lg font-semibold text-gray-200">Sous étape</h3>
                </div>
                <p class="mt-2 text-sm text-gray-100 flex justify-center text-center">
                  <b>Présentation des compétences nécessaires au chef d'entreprise.</b>
                </p>
              </div>
            </div>
          <div class="flex flex-col items-center justify-center">
            <div class="mb-4 border-b border-gray-700">
              <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li class="mr-2" role="presentation">
                  <button class="inline-block p-4 rounded-t-lg border-b-2 text-[#29398c] hover:text-blue-600 border-[#29398c]" id="first-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">1</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700" id="second-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">2</button>
                </li>
                <li class="mr-2" role="presentation">
                  <button class="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:border-transparent text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700" id="third-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">3</button>
                </li>
              </ul>
            </div>
          </div>
          <div id="btnStartAnnim" class="flex justify-center"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Information',
  props: {
    msg: String,
  },
  mounted() {
    const totalhours = document.getElementById('totalhours');

    // tab js
    const tabs = document.querySelectorAll('[data-tabs-target]');
    const tabContents = document.querySelectorAll('[data-tabs-content]');
    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        const target = document.querySelector(tab.dataset.tabsTarget);
        tabContents.forEach((tabContent) => {
          tabContent.classList.remove('block');
          tabContent.classList.add('hidden');
        });
        target.classList.remove('hidden');
        target.classList.add('block');
        tabs.forEach((tab) => {
          tab.classList.remove('border-gray-100', 'dark:border-gray-700', 'text-gray-500', 'dark:text-gray-400', 'border-b-2');
          tab.classList.add('border-transparent', 'text-gray-400', 'dark:text-gray-300', 'hover:text-gray-600', 'hover:border-gray-300', 'dark:hover:text-gray-300');
        });
        tab.classList.remove('border-transparent', 'text-gray-400', 'dark:text-gray-300', 'hover:text-gray-600', 'hover:border-gray-300', 'dark:hover:text-gray-300');
        tab.classList.add('border-gray-100', 'dark:border-gray-700', 'text-gray-500', 'dark:text-gray-400', 'border-b-2');
      });
    });

    // if on firts tab totalhours was given 4h , if on second tab totalhours was given 7h , if on third tab totalhours was given 9h
    const tab1 = document.getElementById('first-tab');
    const tab2 = document.getElementById('second-tab');
    const tab3 = document.getElementById('third-tab');
    tab1.addEventListener('click', () => {
      totalhours.innerHTML = '10h';
    });
    tab2.addEventListener('click', () => {
      totalhours.innerHTML = '4h';
    });
    tab3.addEventListener('click', () => {
      totalhours.innerHTML = '6h';
    });


    // create button play and pause in #btnStartAnnim for change tab automaticaly when click on pause stop change tab and start play when window is focus
    const btnStartAnnim = document.getElementById('btnStartAnnim');
    const btnPlay = document.createElement('button');
    btnPlay.innerHTML = 'Play';
    btnPlay.classList.add('bg-[#29398c]', 'text-white', 'px-4', 'py-2', 'rounded-md', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:ring-2', 'focus:ring-blue-600', 'focus:ring-opacity-50', 'transition', 'duration-500', 'ease-in-out', 'transform', 'hover:-translate-y-1', 'hover:scale-110', 'active:scale-90', 'active:translate-y-0');
    const btnPause = document.createElement('button');
    btnPause.innerHTML = 'Pause';
    btnPause.classList.add('bg-[#29398c]', 'text-white', 'px-4', 'py-2', 'rounded-md', 'shadow-md', 'hover:bg-blue-600', 'focus:outline-none', 'focus:ring-2', 'focus:ring-blue-600', 'focus:ring-opacity-50', 'transition', 'duration-500', 'ease-in-out', 'transform', 'hover:-translate-y-1', 'hover:scale-110', 'active:scale-90', 'active:translate-y-0');
    btnStartAnnim.appendChild(btnPlay);
    btnStartAnnim.appendChild(btnPause);
    btnPause.style.display = 'none';

    // change tab automaticaly
    let i = 0;
    const changeTab = () => {
      if (i === 0) {
        tab1.click();
        i = 1;
      } else if (i === 1) {
        tab2.click();
        i = 2;
      } else if (i === 2) {
        tab3.click();
        i = 0;
      }
    };

    let interval;
    btnPlay.addEventListener('click', () => {
      interval = setInterval(changeTab, 5000);
      btnPlay.style.display = 'none';
      btnPause.style.display = 'block';
    });
    btnPause.addEventListener('click', () => {
      clearInterval(interval);
      btnPause.style.display = 'none';
      btnPlay.style.display = 'block';
    });

    // window load play
    window.addEventListener('load', () => {
      interval = setInterval(changeTab, 5000);
      btnPlay.style.display = 'none';
      btnPause.style.display = 'block';
    });
  },


};
</script>

<style scoped>

</style>
