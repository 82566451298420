<template>
  <nav id="Home" class="border-b-4 border-[#29398c] bg-slate-200 px-2 sm:px-4 py-2.5 rounded">
    <div class="container flex flex-wrap justify-between items-center mx-auto">
      <img src="../assets/OAP_LogoMain.png" class="w-10 h-10" alt="OAP_LOGO">
      <button id="hamburger" data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-[#29398c] rounded-lg md:hidden" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul class="flex flex-col p-4 mt-4 text-center  rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 items-center">
          <li>
            <a id="Link" class="link block py-2 pr-4 pl-3 text-white bg-[#29398c] rounded md:bg-transparent md:text-[#29398c] md:p-0 hover:cursor-pointer" aria-current="page">Acceuil</a>
          </li>
          <li>
            <a id="SecondLink" class="link block py-2 pr-4 pl-3 text-gray-700 rounded sm:hover:bg-[#29398c] sm:hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-[#29398c] md:p-0 hover:cursor-pointer">À propos</a>
          </li>
          <li>
            <a id="ThirdLink" class="link block py-2 pr-4 pl-3 text-gray-700 rounded sm:hover:bg-[#29398c] sm:hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-[#29398c] md:p-0 hover:cursor-pointer">Formations</a>
          </li>
          <li>
            <a id="LastLink" class="link block py-2 pr-4 pl-3 text-gray-700 rounded sm:hover:bg-[#29398c] sm:hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-[#29398c] md:p-0 hover:cursor-pointer">Nous Contacter</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navbar",
  components: {},
  mounted() {
    // eslint-disable-next-line no-undef
    const collapse = document.querySelectorAll('[data-collapse-toggle]');
    collapse.forEach((item) => {
      item.addEventListener('click', () => {
        const target = item.getAttribute('data-collapse-toggle');
        const targetElement = document.getElementById(target);
        targetElement.classList.toggle('hidden');
      });
    });

    // change hamburger svg to x if navbar is open
    const hamburger = document.getElementById('hamburger');
    const navbar = document.getElementById('navbar-default');
    hamburger.addEventListener('click', () => {
      if (navbar.classList.contains('hidden')) {
        hamburger.innerHTML = '<span class="sr-only">Open main menu</span><svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>';
      } else {
        hamburger.innerHTML = '<span class="sr-only">Close main menu</span><svg class="w-8 h-8" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>';
      }
    });

    // hover on a link in navbar make line appear under it start on the left side to the right side
    const links = document.querySelectorAll('.link');
    links.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        const line = document.createElement('span');
        line.classList.add('line');
        link.appendChild(line);
        setTimeout(() => {
          line.style.width = '100%';
        }, 50);
      });
      link.addEventListener('mouseleave', () => {
        const line = link.querySelector('.line');
        line.style.width = '0%';
        setTimeout(() => {
          line.remove();
        }, 500);
      });
    });
    // get link of navbar and add annimation behavior on scroll
    const navbarLinks = document.querySelectorAll('.navbar-link');
    navbarLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const target = link.getAttribute('href');
        const targetElement = document.querySelector(target);
        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  },
}
</script>

<style scoped>
.link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #29398c;
  transition: width .3s;
}
.link:hover::after {
  width: 100%;
  transition: width .3s;
}

@media (max-width: 768px) {
  .link::after {
    display: none;
  }
}

</style>
