<template>
  <div class="wrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Loader"
}
</script>

<style scoped>

</style>