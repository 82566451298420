import { createApp } from 'vue'
import App from './App.vue'

import './input.css';
import 'flowbite';

createApp(App).mount('#app')

// if location in on http force Https
// if (location.protocol !== 'https:') {
//     location.replace(`https:${location.href.substring(location.protocol.length)}`);
// }



const FirstLink = document.querySelectorAll('#Link');
const SecondLink = document.querySelectorAll('#SecondLink');
const ThirdLink = document.querySelectorAll('#ThirdLink');
const LastLink = document.querySelectorAll('#LastLink');


const Home = document.getElementById('Home');
const About = document.getElementById('About');
const Formation = document.getElementById('Formations');
const Contact = document.getElementById('Contact');

FirstLink.forEach((link) => {
    link.addEventListener('click', () => {
        Home.scrollIntoView({ behavior: 'smooth' });
    });
});

SecondLink.forEach((link) => {
    link.addEventListener('click', () => {
        About.scrollIntoView({ behavior: 'smooth' });
    });
});

ThirdLink.forEach((link) => {
    link.addEventListener('click', () => {
        Formation.scrollIntoView({ behavior: 'smooth' });
    });
});

LastLink.forEach((link) => {
    link.addEventListener('click', () => {
        Contact.scrollIntoView({ behavior: 'smooth' });
    });
});