<template>
  <footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8">
    <div class="sm:flex sm:ml-16 sm:items-center md:justify-between">
      <a class="flex items-center mb-4 sm:mb-0 justify-center">
        <img src="../assets/OAP_LogoMain.png" class="mr-3 h-8" alt="OAP Logo">
      </a>
      <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 justify-center">
        <li>
          <a id="Link" class="mr-4 hover:text-[#29398c] md:mr-6 hover:cursor-pointer">Acceuil</a>
        </li>
        <li>
          <a id="SecondLink" class="mr-4 hover:text-[#29398c] md:mr-6 hover:cursor-pointer">À propos</a>
        </li>
        <li>
          <a id="ThirdLink" class="mr-4 hover:text-[#29398c] md:mr-6 hover:cursor-pointer ">Formations</a>
        </li>
        <li>
          <a id="LastLink" class="hover:text-[#29398c] hover:cursor-pointer">Contact</a>
        </li>
      </ul>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8">
    <span class="block text-sm text-gray-500 text-center">© 2022 <a id="Link" class="hover:text-[#29398c] hover:cursor-pointer">OAP™</a>. All Rights Reserved.
    </span>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FooterVue"
}
</script>

<style scoped>

</style>