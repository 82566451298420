<template>
  <section id="About">
    <div class="p-12 pt-8 mx-auto">
      <h2 class="text-center text-4xl font-bold text-gray-900 p-8">Qui Sommes Nous ?</h2>
      <div id="accordion-collapse" data-accordion="collapse">
        <h2 id="accordion-collapse-heading-1">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 rounded-t-xl hover:bg-[#29398c] hover:text-white bg-gray-100 text-gray-900" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
            <span>Qu'est ce que OAP ?</span>
            <svg data-accordion-icon="" class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-1" class="" aria-labelledby="accordion-collapse-heading-1">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">OAP est un Organisme de Formation spécialisé dans la Création d'Entreprise.</p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-2">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
            <span>L'objectif de notre Formation ?</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">L'objectif de cette formation est d'apprendre comment créer son entreprise en ligne. Nous allons vous montrer comment trouver et tester une idée, faire un business plan, s'assurer de la viabilité du projet, trouver des financements (si vous en avez besoin), assurer la gestion marketing, commerciale et juridique, encadrer des collaborateurs, incarner votre rôle de dirigeant en sachant représenter l'entreprise, et, si vous le souhaitez, remplir les formalités administratives (facultatif).</p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span>Nos Formateurs</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
                Notre équipe, jeune et dynamique, offre une grande flexibilité, et nous serons là pour vous accompagner tout au long de votre formation. L'équipe administrative prendra en charge toutes les démarches nécessaires et sera également disponible pour vous soutenir en cas de besoin. Notre équipe pédagogique est composée de trois experts spécialisés dans leurs domaines respectifs de formation. Nous serions ravis de vous accueillir chez Créa Formations !</p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-4">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span>Politique du centre</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
              Nous sommes ravis de vous transmettre ce livret d’accueil dans le but de vous accueillir au sein de notre centre de formation à distance.
              Nous souhaitons vous faire savoir que le rôle général de nos partenaires et membres doit être dans une démarche positive et d’encadrement des apprenants.
              Le rôle de chacun est un rôle de conseil et de partage.
              Notre priorité est la satisfaction des apprenants et la qualité des échanges tant sur le plan de la formation que sur le plan humain.
              Toute collaboration passe par un processus d’entraide et d’écoute.
              Nous sommes sûrs que nos valeurs communes seront en adéquation avec la politique du centre.
            </p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span>Accès à la plateforme</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
              L’accès à la plateforme se fait à travers le lien reçu par mail, celui-ci reste actif sans limite de temps. Pour accéder à la plateforme il vous suffit de cliquer sur le lien et de rentrer votre adresse mail ainsi que votre mot de passe provisoire reçu par mail.
              Vous pouvez réinitialiser votre mot de passe si vous le souhaitez ou si vous en avez besoin.
              L’accès aux formations (Parcours créer son entreprise et Test de positionnement) se fait simplement en cliquant sur l’encart de la formation que vous trouverez en page de connexion de votre espace. Après avoir cliquer sur l’encart de votre parcours de formation vous pourrez ensuite naviguer sur votre espace et sélectionner les modules que vous souhaitez regarder, vous recevrez en parrallèle les différents quizz tout au long de votre formation.
              Pendant vos différents cours vous aurez des sessions questions/Réponses afin de pouvoir dialoguer avec votre formateur.
              Vous pourrez choisir vos différents cours en replays avec votre souris sur votre espace.
              Il vous suffira de fermer la fenêtre de votre navigateur pour quitter la formation, votre progression sera bien entendu enregistrée automatiquement.
            </p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-6">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span>Accès à la formation</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
              Vous recevrez un mail pour accéder à la classe virtuelle lors de votre inscription avant la formation ainsi qu’un mail pour accéder au test de positionnement 48h après la signature du contrat, de plus vous recevrez par mail vos accès à la plateforme de formation avec pour identifiant votre adresse mail ainsi qu’un mot de passe provisoire, vous aurez d’implémenter dans votre espace apprenant votre livret d’accueil, les CGV ainsi que vos dates des différentes sessions de formation.
              Les membres du centre de formation restent à votre entière disposition en cas de problème de connexion ainsi que le responsable technique, les coordonnées sont disponibles dans le livret d’accueil.
              Après la formation, le contenu restera accessible sur la plateforme (sans limite de durée), les mises à jour éventuelles seront comprises ainsi que des ressources en PDF ( Slides du cours de formation).
            </p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-7">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span>Absences</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
              En cas d’absence de la part de l’apprenant il sera demandé un justificatif et une notification de l’absence qui sera transmise à l’entreprise par mail, aucune adaptation de la formation en cours ne pourra être effectuée.
              Un replay des modules manqués sera disponible sur notre plateforme en ligne.
            </p>
          </div>
        </div>
        <h2 id="accordion-collapse-heading-8">
          <button type="button" class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0  hover:bg-[#29398c] hover:text-white bg-gray-100" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span>Abandons et Dysfonctionnement</span>
            <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
          <div class="p-5 font-light border border-b-0 border-gray-200">
            <p class="mb-2 text-gray-500">
              En tant qu’apprenant, nous souhaitons vous sensibiliser sur votre implication personnelle pendant votre formation.
              Celle-ci est primordiale pour une réussite et atteindre l’objectif recherché.
              Notre équipe pédagogique et votre formateur sont à votre entière disposition pour discuter de toutes situations entraînant une difficulté avant, pendant et après votre formation.
              Toute personne qu’elle soit une entreprise, un partenaire ou un apprenant remarquant un
              dysfonctionnement aura la possibilité de nous faire parvenir son signalement par mail, par téléphone ou par l’intermédiaire de son formateur ou de tout autre membre du centre de formation.
              En cas de problème de connexion ou autres les membres du centre de formation se tiennent à votre disposition à travers l’organigramme.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  components: {},
  // mounted() {
  //   // collapse all accordion items
  //   document.querySelectorAll("[data-accordion]").forEach((accordion) => {
  //     accordion.addEventListener("click", (event) => {
  //       if (event.target.hasAttribute("data-accordion-target")) {
  //         event.preventDefault();
  //         const target = document.querySelector(
  //             event.target.getAttribute("data-accordion-target")
  //         );
  //         const expanded = event.target.getAttribute("aria-expanded") === "true";
  //         event.target.setAttribute("aria-expanded", !expanded);
  //         target.classList.toggle("hidden");
  //         const icon = event.target.querySelector("[data-accordion-icon]");
  //         icon.classList.toggle("rotate-180");
  //
  //       }
  //     });
  //   });
  // },
};
</script>

<style scoped>

</style>
